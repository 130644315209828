/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 food.gltf 
Author: Liberi Arcano (https://sketchfab.com/LiberiArcano)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/3december-2021-day-9-cooking-8f36a5348ed54ebf95fbd0e4611a47f7
Title: 3December 2021 Day 9: Cooking
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/food.gltf");
  const mesh = useRef(null);
  // for each render or each frame adding a point zero one
  useFrame(() => {
    mesh.current.rotation.y += 0.01;
  });

  return (
    <group
      rotation={[0.4, 1, 0]}
      position={[-0.2, -0.5, 0]}
      scale={0.9}
      dispose={null}
      ref={mesh}
    >
      <group scale={0.01}>
        <group rotation={[-Math.PI / 2, 0, -Math.PI]} scale={100}>
          <mesh
            geometry={nodes.Cylinder018_FryingPan_0.geometry}
            material={materials.FryingPan}
          />
          <mesh
            geometry={nodes.Cylinder018_Fire_0.geometry}
            material={materials.Fire}
          />
          <mesh
            geometry={nodes.Cylinder018_Sparks_0.geometry}
            material={materials.Sparks}
          />
          <mesh
            geometry={nodes.Cylinder018_FryingPan_Handle_0.geometry}
            material={materials.FryingPan_Handle}
          />
          <mesh
            geometry={nodes.Cylinder018_Oil_0.geometry}
            material={materials.material}
          />
          <mesh
            geometry={nodes.Cylinder018_Herbs_0.geometry}
            material={materials.Herbs}
          />
          <mesh
            geometry={nodes.Cylinder018_Potato_0.geometry}
            material={materials.Potato}
          />
          <mesh
            geometry={nodes.Cylinder018_Salmon_Base_0.geometry}
            material={materials.Salmon_Base}
          />
          <mesh
            geometry={nodes.Cylinder018_Salmon_Tender_0.geometry}
            material={materials.Salmon_Tender}
          />
          <mesh
            geometry={nodes.Cylinder018_White_0.geometry}
            material={materials.White}
          />
          <mesh
            geometry={nodes.Cylinder018_Seeds_0.geometry}
            material={materials.Seeds}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/food.gltf");
