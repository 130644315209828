import classes from "./PersonalPage.module.css";
import React from "react";
import personal from "../assets/personal.png";

function PersonalPage() {
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <div className={classes.title_container}>
          <div>
            <div className={classes.title_row}>
              <h1 className={classes.icon}>♣</h1>
              <h1>Animated portfolio</h1>
              <h1 className={classes.icon}>♣</h1>
            </div>
            <div className={classes.content_row}>
              <h3>UI Design, Front-end Development</h3>
              <h3>Dev tools: Figma, React Native</h3>
              <h2>Personal website designed using React and Firebase.</h2>
            </div>
            <a
              style={{ textDecoration: "none" }}
              href="https://nanxi-c637e.web.app/"
              target="_blank"
            >
              <button className={classes.view_website}>View Weibsite ➜</button>
            </a>
          </div>
        </div>
        <div className={classes.imgbox}>
          <img
            alt="title_img"
            src={personal}
            className={classes.title_img}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default PersonalPage;
