import classes from "./MobilePage.module.css";
import React from "react";
import Scene from "./TruckScene";
import { Canvas } from "@react-three/fiber";
import { Link } from "react-router-dom";

function MobilePage() {
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <div className={classes.title_container}>
          <div>
            <div className={classes.title_row}>
              <h1 className={classes.icon}>♠️</h1>
              <h1>Community collection application</h1>
              <h1 className={classes.icon}>♠️</h1>
            </div>
            <div className={classes.content_row}>
              <h3>UI Design, App Front-end Development</h3>
              <h3>Dev tools: Figma, React Native</h3>
              <h2>
                This mobile application would contribute to a more
                environmentally friendly society by helping people find places
                to collect hard rubbish such as furniture and improving their
                recycling habits.
              </h2>
              <h2>
                The application supports GPS, camera and voice sensors and
                allows users to send messages to each other and share more
                information.
              </h2>
            </div>
            <Link
              to={`/community-collection`}
              style={{ textDecoration: "none" }}
            >
              <button className={classes.view_website}>View Project ➜</button>
            </Link>
          </div>
        </div>

        <Canvas shadows>
          <ambientLight intensity={1} />
          <directionalLight
            color="white"
            position={[-5, 10, 5]}
            intensity={1.5}
            castShadow
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
          />
          <pointLight color="white" position={[0, 2, 0]}></pointLight>
          <Scene />
        </Canvas>
      </div>
    </div>
  );
}

export default MobilePage;
