import classes from "./Details.module.css";
import React from "react";
import ProgressBar from "../components/ProgressBar";
function Details() {
  return (
    <div className={classes.container}>
      <div className={classes.content_cards}>
        <div className={classes.title}>
          <h1>Hi,</h1>
          <h2>
            I'm Nanxi, currently living in Singapore. Graduated from the
            University of Melbourne, Master of Information Technology, major in
            HCI. My projects includes UX research, UI design and front-end
            development.
          </h2>
          <h2>
            Passionate and detail-oriented UX/UI designer with a strong
            background in UX research. Also Familiarity with coding that enables
            me to quickly create and confirm the effectiveness of various
            experiences.
          </h2>
        </div>
        <div className={classes.content_skills}>
          <h1>Tech skills</h1>
          <div className={classes.content_bar}>
            <ProgressBar
              value={100}
              name={"JavaScript"}
              loading="lazy"
              src={"https://cdn.worldvectorlogo.com/logos/javascript-1.svg"}
            />
            <ProgressBar
              value={100}
              name={"HTML"}
              loading="lazy"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/1024px-HTML5_Badge.svg.png"
              }
            />
            <ProgressBar
              value={100}
              name={"CSS"}
              loading="lazy"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/CSS3_logo.svg/800px-CSS3_logo.svg.png"
              }
            />
            <ProgressBar
              value={100}
              name={"React JS"}
              loading="lazy"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
              }
            />
            <ProgressBar
              value={80}
              name={"React Native"}
              loading="lazy"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
              }
            />
            <ProgressBar
              value={80}
              name={"SwiftUI"}
              loading="lazy"
              src={
                "https://images.ctfassets.net/ooa29xqb8tix/4bWEQMRsA6PzjiHljsxryp/55d78d84da072e9c22caddbccd67396b/swift-logo.png?w=400&q=50"
              }
            />
            <ProgressBar
              value={60}
              name={"Three JS"}
              loading="lazy"
              src={
                "https://global.discourse-cdn.com/standard17/uploads/threejs/original/2X/e/e4f86d2200d2d35c30f7b1494e96b9595ebc2751.png"
              }
            />
            <ProgressBar
              value={50}
              name={"Node JS"}
              loading="lazy"
              src={
                "https://seeklogo.com/images/N/nodejs-logo-FBE122E377-seeklogo.com.png"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
