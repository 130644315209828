import classes from "./CookingPage.module.css";
import React from "react";
import Scene from "./Food";
import { Canvas } from "@react-three/fiber";
import { Link } from "react-router-dom";

function CookingPage() {
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <div className={classes.title_container}>
          <div className={classes.title_row}>
            <h1 className={classes.icon}>♦</h1>
            <h1>Cooking with ingredients</h1>
            <h1 className={classes.icon}>♦</h1>
          </div>
          <div className={classes.content_row}>
            <h3>UI Design, UX Research</h3>
            <h3>Dev tools: Adobe XD, Treejack, Chalkmark</h3>
            <h2>
              The application trying to give the user inspiration of cook with
              ingredients they have and create a wide platform for people to
              share their creative recipes and ideas, whether they are
              professional chefs or home chefs.
            </h2>
          </div>

          <Link to={`/cooking`} style={{ textDecoration: "none" }}>
            <button className={classes.view_website}>View Project ➜</button>
          </Link>
        </div>

        <Canvas className={classes.img_container}>
          <ambientLight intensity={1} />
          <directionalLight
            color="white"
            position={[-5, 10, 5]}
            intensity={1.5}
            castShadow
          />
          <pointLight color="white" position={[0, 2, 0]}></pointLight>
          <Scene />
        </Canvas>
      </div>
    </div>
  );
}

export default CookingPage;
