import React from "react";
import phone_icon from "../assets/icon.png";
import prayericon from "../assets/prayericon.png";
import appstore from "../assets/appstore.png";
import { Link } from "react-router-dom";
import classes from "./Apps.module.css";

function Apps() {
  return (
    <div className={classes.container}>
      <div className={classes.content_cards}>
        <img alt="icon" src={phone_icon} className={classes.img_icon}></img>
        <div>
          <h1 className={classes.title}>Tiny Planner: Tasks & To Do</h1>

          <div className={classes.appstore}>
            <Link to={`/tinyplanner`} style={{ textDecoration: "none" }}>
              <button className={classes.arrowbutton}>
                See more details<span className={classes.arrow}></span>
              </button>
            </Link>
            <a href="https://apps.apple.com/cn/app/tiny-planner-tasks-to-do/id6472772555?l=en-GB">
              <img
                alt="appstore"
                src={appstore}
                className={classes.button_img}
              ></img>
            </a>
          </div>
        </div>
      </div>

      <div className={classes.content_cards}>
        <img
          alt="icon"
          src={prayericon}
          className={classes.img_icon}
          style={{ borderRadius: "60%" }}
        ></img>
        <div>
          <h1 className={classes.title}>Prayer For Blessings</h1>
          <div className={classes.appstore}>
            <Link to={`/prayerforblessings`} style={{ textDecoration: "none" }}>
              <button className={classes.arrowbutton}>
                See more details<span className={classes.arrow}></span>
              </button>
            </Link>
            <a href="https://apps.apple.com/us/app/%E7%94%B5%E5%AD%90%E7%A5%88%E7%A6%8F/id6478762745">
              <img
                alt="appstore"
                src={appstore}
                className={classes.button_img}
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Apps;
