import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

function ProgressBar(props) {
  return (
    <div style={{ width: 100, height: 100 }}>
      <CircularProgressbarWithChildren
        value={props.value}
        styles={buildStyles({
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "butt",

          // Colors
          pathColor: `rgba(187, 134, 252, ${props.value / 100})`,
          textColor: "#ffffff",
          trailColor: "#f1e6fe",
        })}
      >
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <img style={{ width: 34, marginTop: -5 }} src={props.src} alt="logo" />
        <div style={{ fontSize: 12, marginTop: 1 }}>
          <strong>{props.name}</strong>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default ProgressBar;
