import * as THREE from "three";
import React, { Suspense, useState, useRef, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import {
  PerspectiveCamera,
  Environment,
  MeshDistortMaterial,
  ContactShadows,
} from "@react-three/drei";

export default function Scene() {
  const sphere = useRef();
  const light = useRef();
  const [mode, setMode] = useState(false);
  const [down, setDown] = useState(false);
  const [hovered, setHovered] = useState(false);
  // const box = useRef(null);
  // // for each render or each frame adding a point zero one
  // useFrame(() => (box.current.rotation.x = box.current.rotation.y += 0.01));

  // Change cursor on hovered state
  // useEffect(() => {
  //   document.body.style.cursor = hovered
  //     ? "none"
  //     : `url('data:image/svg+xml;base64,${btoa(
  //         '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="10" fill="#E8B059"/></svg>'
  //       )}'), auto`;
  // }, [hovered]);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  // Make the bubble float and follow the mouse
  // This is frame-based animation, useFrame subscribes the component to the render-loop
  useFrame((state) => {
    light.current.position.x = state.mouse.x * 20;
    light.current.position.y = state.mouse.y * 20;
    if (sphere.current) {
      sphere.current.position.x = THREE.MathUtils.lerp(
        sphere.current.position.x,
        hovered ? state.mouse.x / 2 : 0,
        0.2
      );
      sphere.current.position.y = THREE.MathUtils.lerp(
        sphere.current.position.y,
        Math.sin(state.clock.elapsedTime / 1.5) / 6 +
          (hovered ? state.mouse.y / 2 : 0),
        0.2
      );
    }
  });

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 0, 4]}>
        <ambientLight intensity={mode && !hovered ? 1.5 : 0.5} />
        <pointLight
          ref={light}
          position-z={-15}
          intensity={mode && !hovered ? 0.4 : 1}
          color="#F8C069"
        />
      </PerspectiveCamera>
      <Suspense fallback={null}>
        <mesh
          position={[0, 1, 0]}
          ref={sphere}
          scale={down ? 1.2 : hovered ? 1.05 : 1}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onPointerDown={() => setDown(true)}
          onPointerUp={() => {
            setDown(false);
            // Toggle mode between dark and bright
            setMode(!mode);
          }}
        >
          <sphereGeometry args={[1, 40, 40]} />
          <MeshDistortMaterial
            color={hovered ? "#bb86fc" : mode ? "#cf6679" : "white"}
            envMapIntensity={mode && !hovered ? 0.4 : 1}
            clearcoat={mode && !hovered ? 0.04 : 1}
            clearcoatRoughness={0}
            metalness={0.1}
          />
        </mesh>
        <Environment preset="warehouse" />
        <ContactShadows
          rotation={[Math.PI / 2, 0, 0]}
          position={[0, -1.6, 0]}
          opacity={mode ? 0.8 : 0.4}
          width={15}
          height={15}
          blur={2.5}
          far={1.6}
        />
        {/* <mesh position={[5, 1, 2]} ref={box}>
          <boxGeometry attach="geometry" args={[2, 2, 1]} />

          <meshStandardMaterial attach="material" color="pink" />
        </mesh> */}
      </Suspense>
    </>
  );
}
