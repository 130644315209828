import React from "react";
import prayericon from "../assets/prayericon.png";
import appstore from "../assets/appstore.png";
import { Link } from "react-router-dom";
import classes from "./Prayer.module.css";

import phone_1 from "../assets/p1.png";
import phone_1_lower from "../assets/p1_lower.png";
import phone_2 from "../assets/p2.png";
import phone_2_lower from "../assets/p2_lower.png";
import phone_3 from "../assets/p3.png";
import phone_3_lower from "../assets/p3_lower.png";
import phone_4 from "../assets/p4.png";
import phone_4_lower from "../assets/p4_lower.png";

import phone_5 from "../assets/p5.png";
import phone_5_lower from "../assets/p5_lower.png";
import phone_6 from "../assets/p6.png";
import phone_6_lower from "../assets/p6_lower.png";
import phone_7 from "../assets/p7.png";
import phone_7_lower from "../assets/p7_lower.png";

function Prayer() {
  return (
    <div className={classes.container}>
      <div className={classes.content_cards}>
        <img
          alt="icon"
          src={prayericon}
          className={classes.img_icon}
          style={{ borderRadius: "60%" }}
        ></img>
        <div>
          <h1 className={classes.title}>Prayer For Blessings</h1>
          <h3 className={classes.title2}>
            Touch of Zen - Your Virtual Sanctuary
          </h3>
          <div className={classes.appstore}>
            <a href="https://apps.apple.com/us/app/%E7%94%B5%E5%AD%90%E7%A5%88%E7%A6%8F/id6478762745">
              <img
                alt="appstore"
                src={appstore}
                className={classes.button_img}
              ></img>
            </a>
            <Link to={`/pprivacypolicy`} style={{ textDecoration: "none" }}>
              <h3>Privacy Policy</h3>
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.content_cards}>
        {/* 1,3 */}
        <h1 className={classes.sub_title}>
          Engage in virtual Buddhist practices, enrich your understanding of
          Buddhist teachings, and find tranquility in our carefully curated
          digital sanctuary
        </h1>
        <div className={classes.img_cards}>
          <img
            alt="1"
            style={{
              backgroundImage: `url("${phone_1_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_1}
            className={classes.img_box}
          ></img>
          <img
            alt="3"
            style={{
              backgroundImage: `url("${phone_3_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_3}
            className={classes.img_box}
          ></img>
        </div>
      </div>
      <div className={classes.content_cards}>
        {/* 4,5 */}
        <div className={classes.img_cards}>
          <img
            alt="4"
            style={{
              backgroundImage: `url("${phone_4_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_4}
            className={classes.img_box}
          ></img>
          <img
            alt="5"
            style={{
              backgroundImage: `url("${phone_5_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_5}
            className={classes.img_box}
          ></img>
        </div>
        <h1 className={classes.sub_title}>
          Offers a variety of popular Buddha statues and offerings
        </h1>
      </div>
      <div className={classes.content_cards}>
        {/* 2,6 */}
        <h1 className={classes.sub_title}>
          Add and edit your own Buddha statues
        </h1>
        <div className={classes.img_cards}>
          <img
            alt="2"
            style={{
              backgroundImage: `url("${phone_2_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_2}
            className={classes.img_box}
          ></img>
          <img
            alt="6"
            style={{
              backgroundImage: `url("${phone_6_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_6}
            className={classes.img_box}
          ></img>
        </div>
      </div>
      <div className={classes.content_cards}>
        {/* 7 */}
        <div className={classes.img_cards}>
          <img
            alt="7"
            style={{
              backgroundImage: `url("${phone_7_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_7}
            className={classes.img_box}
          ></img>
        </div>
        <h1 className={classes.sub_title}>Customized Widgets</h1>
      </div>
    </div>
  );
}
export default Prayer;
