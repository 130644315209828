import { React, useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Scene from "../components/Scene";
import { Html } from "@react-three/drei";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["DESIGNER", "DEVELOPER"];

function HomePage() {
  const [index, setTextFastIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setTextFastIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className={classes.container}>
      <Canvas dpr={[1, 2]}>
        <Scene />
        <OrbitControls
          enablePan={false}
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Html fullscreen>
          <div className={classes.text}>
            <div className={classes.text_container}>
              <h1>NANXI LI</h1>
              <div className={classes.title_one_row}>
                <h2>UI & UX</h2>
                <TextTransition
                  className={classes.title1}
                  springConfig={presets.wobbly}
                >
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </div>
            </div>
          </div>
        </Html>
      </Canvas>
    </div>
  );
}

export default HomePage;
