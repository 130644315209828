import { useState } from "react";
import classes from "./Navigation.module.css";
import { NavLink } from "react-router-dom";
import { Git, Linkedin, FilePerson } from "react-bootstrap-icons";
import Resume from "../assets/Nanxi Li.pdf";

function Navigation() {
  // adding the states
  const [isActive, setIsActive] = useState(false);

  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };

  return (
    <nav className={classes.navbar}>
      <ul className={`${classes.navMenu} ${isActive ? classes.active : ""}`}>
        <ul className={classes.logos}>
          <li onClick={removeActive} className={classes.logo}>
            <a href="https://github.com/nanxi194?tab=overview&from=2023-07-01&to=2023-07-06">
              <Git />
            </a>
          </li>
          <li onClick={removeActive} className={classes.logo}>
            <a
              href="https://www.linkedin.com/in/nanxi-li-b639a327a/"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
          </li>
          <li onClick={removeActive} className={classes.logo}>
            <a href={Resume} download="Nanxi Li">
              <FilePerson />
            </a>
          </li>
        </ul>

        <li onClick={removeActive}>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <span>CONTACTS</span>
          </NavLink>
        </li>

        <li onClick={removeActive}>
          <NavLink
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
            to="/details"
          >
            <span>DETAILS</span>
          </NavLink>
        </li>

        <li onClick={removeActive}>
          <NavLink
            to="/projects"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <span>PROJECTS</span>
          </NavLink>
        </li>

        <li onClick={removeActive}>
          <NavLink
            to="/apps"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <span>APPS</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/">
            <span className={classes.content}>
              <h2>N</h2>
              <h2>N</h2>
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
