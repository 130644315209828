/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 scene.gltf 
Author: tojamerlin (https://sketchfab.com/tojamerlin)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/colored-flower-6b3020fed29c4976bc44ed1b7cb7e960
Title: colored flower
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function Model() {
  const mesh = useRef(null);
  // for each render or each frame adding a point zero one
  useFrame(() => {
    mesh.current.rotation.y += 0.01;
  });
  const { nodes, materials } = useGLTF("/scene.gltf");

  return (
    <group
      rotation={[0.5, 1, 0]}
      position={[-0.3, -1.5, 0]}
      scale={0.07}
      dispose={null}
      ref={mesh}
    >
      <group position={[0, 0, 0]}>
        <mesh
          geometry={nodes.Wide_leaf_2_Petal_wild_0.geometry}
          material={materials.Petal_wild}
        />
        <mesh
          geometry={nodes.Wide_leaf_2_Leaf_wild_0.geometry}
          material={materials.Leaf_wild}
        />
        <mesh
          geometry={nodes.Wide_leaf_2_Stigma_wild_0.geometry}
          material={materials.Stigma_wild}
        />
      </group>
      <mesh
        geometry={nodes.flower_patch_2_Petal_flower_patch_0.geometry}
        material={materials.Petal_flower_patch}
      />
      <mesh
        geometry={nodes.flower_patch_2_Leaf_flower_patch_0.geometry}
        material={materials.Leaf_flower_patch}
      />
      <mesh
        geometry={nodes.flower_patch_2_Stem_flower_patch_0.geometry}
        material={materials.Stem_flower_patch}
      />
      <mesh
        geometry={nodes.flower_patch_2_Stigma_flower_patch_0.geometry}
        material={materials.Stigma_flower_patch}
      />
    </group>
  );
}

useGLTF.preload("/scene.gltf");
