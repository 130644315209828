import React from "react";
import FlowerPage from "./FlowerPage";
import MobilePage from "./MobilePage";
import CookingPage from "./CookingPage";
import PersonalPage from "./PersonalPage";
import classes from "./ProjectPage.module.css";
function ProjectPage() {
  return (
    <div className={classes.container}>
      <FlowerPage />
      <MobilePage />
      <CookingPage />
      <PersonalPage />
    </div>
  );
}

export default ProjectPage;
