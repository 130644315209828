import classes from "./FlowerPage.module.css";
import React from "react";
import Scene from "./FlowerScene";
import { Canvas } from "@react-three/fiber";

function FlowerPage() {
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <div className={classes.title_container}>
          <div className={classes.title_row}>
            <h1 className={classes.icon}>♥</h1>
            <h1>Flower shop ecommerce website</h1>
            <h1 className={classes.icon}>♥</h1>
          </div>
          <div className={classes.content_row}>
            <h3>UX & UI Design, Full Stack Development</h3>
            <h3>
              Dev tools: Figma, React JS(Redux, Lazy loading, Router), Node JS
            </h3>
            <h2>
              User-Friendly Interface: website is designed with users'
              convenience in mind, making it easy to navigate and find the
              perfect bouquet or arrangement.
            </h2>
            <h2>
              Comprehensive Catalog: browse, filter, and sort the extensive
              collection of flowers, all neatly categorized for users
              convenience.
            </h2>
          </div>
          <a
            style={{ textDecoration: "none" }}
            href="https://flowershop1888-af4655b709dd.herokuapp.com/"
            target="_blank"
          >
            <button className={classes.view_website}>View Website ➜</button>
          </a>
        </div>

        <div>
          <Canvas>
            <ambientLight intensity={1} />
            <directionalLight
              color="white"
              position={[-5, 10, 5]}
              intensity={1.5}
              castShadow
            />
            <pointLight color="white" position={[0, 2, 0]}></pointLight>
            <Scene />
          </Canvas>
        </div>
      </div>
    </div>
  );
}

export default FlowerPage;
