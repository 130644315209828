import classes from "./CookingProject.module.css";
import search1 from "../assets/search1.png";
import search2 from "../assets/search2.png";
import cshare1 from "../assets/cshare1.png";
import cshare2 from "../assets/cshare2.png";
import upload1 from "../assets/upload1.png";
import upload2 from "../assets/upload2.png";
import profile1 from "../assets/profile1.png";
import profile2 from "../assets/profile2.png";

function CookingProject() {
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <div className={classes.title_container}>
          <div className={classes.title}>
            <h1>Cooking with ingredients</h1>
            <h2>
              This application is a cooking platform to help people communicate
              and find recipes.
            </h2>
            <a href="https://xd.adobe.com/view/adb49d45-80e0-4624-a579-3bd588cd3132-f8e7/">
              <button>View deployed version ➜</button>
            </a>
          </div>
        </div>
        <div className={classes.title_container}>
          <div className={classes.skill}>
            <h1>User Interface Design</h1>
            <h2>User Experience research</h2>
          </div>
        </div>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_title}>
          <h1>Searach for recipes, videos, posts</h1>
          <h2>
            Helpful for users to search the recipes using their favourite
            ingredients.
          </h2>
        </div>

        <div className={classes.content_container}>
          <img alt="search2" src={search2} className={classes.box}></img>
          <img alt="search1" src={search1} className={classes.overlay}></img>
        </div>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_container1}>
          <img alt="cshare2" src={cshare2} className={classes.box1}></img>
          <img alt="cshare1" src={cshare1} className={classes.overlay1}></img>
        </div>

        <div className={classes.content_title}>
          <h1>Comment, reply, like and share your favorite recipes</h1>
          <h2>
            Easily measurement conversion and direct messaging to the author
          </h2>
        </div>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_title}>
          <h1>Upload your own secret recipes</h1>
          <h2>Sharing your cooking tips at forum.</h2>
        </div>

        <div className={classes.content_container}>
          <img alt="upload1" src={upload1} className={classes.box}></img>
          <img alt="upload2" src={upload2} className={classes.overlay}></img>
        </div>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_container1}>
          <img alt="profile1" src={profile1} className={classes.box1}></img>
          <img alt="profile2" src={profile2} className={classes.overlay1}></img>
        </div>

        <div className={classes.content_title}>
          <h1>Personal profile</h1>
          <h2>Find out the work you have uploaded and collected.</h2>
        </div>
      </div>
    </div>
  );
}

export default CookingProject;
