import { Outlet } from "react-router-dom";
import Navigation from "../components/Navigation";
import ScrollToTop from "../ScrollToTop";

function RootLayout() {
  return (
    <>
      <ScrollToTop />
      <Navigation />

      <Outlet />
    </>
  );
}

export default RootLayout;
