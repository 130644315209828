import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./pages/Root";
import HomePage from "./pages/HomePage";
import ProjectPage from "./pages/ProjectPage";
import MobileProject from "./components/MobileProject";
import CookingProject from "./components/CookingProject";
import Details from "./pages/Details";
import Contact from "./pages/Contact";
import Weeklyplanner from "./pages/Weeklyplanner";
import Prayer from "./pages/Prayer";
import Apps from "./pages/Apps";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

import PrivacyPolicyPage1 from "./pages/PrivacyPolicyPage1";

import "./App.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "/projects", element: <ProjectPage /> },
        { path: "/community-collection", element: <MobileProject /> },
        { path: "/cooking", element: <CookingProject /> },
        { path: "/details", element: <Details /> },
        { path: "/contact", element: <Contact /> },
        { path: "/tinyplanner", element: <Weeklyplanner /> },
        { path: "/apps", element: <Apps /> },
        { path: "/prayerforblessings", element: <Prayer /> },
        { path: "/privacypolicy", element: <PrivacyPolicyPage /> },
        { path: "/pprivacypolicy", element: <PrivacyPolicyPage1 /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
