import React from "react";
import { Link } from "react-router-dom";
import phone_1 from "../assets/1.png";
import phone_1_lower from "../assets/1_lower.png";
import phone_2 from "../assets/2.png";
import phone_2_lower from "../assets/2_lower.png";
import phone_3 from "../assets/3.png";
import phone_3_lower from "../assets/3_lower.png";
import phone_4 from "../assets/4.png";
import phone_4_lower from "../assets/4_lower.png";

import phone_5 from "../assets/5.png";
import phone_5_lower from "../assets/5_lower.png";
import phone_6 from "../assets/6.png";
import phone_6_lower from "../assets/6_lower.png";
import phone_7 from "../assets/7.png";
import phone_7_lower from "../assets/7_lower.png";
import phone_9 from "../assets/9.png";
import phone_9_lower from "../assets/9_lower.png";
import phone_10 from "../assets/10.png";
import phone_10_lower from "../assets/10_lower.png";
import phone_11 from "../assets/11.png";
import phone_11_lower from "../assets/11_lower.png";
import phone_12 from "../assets/12.png";
import phone_12_lower from "../assets/12_lower.png";
import phone_icon from "../assets/icon.png";
import appstore from "../assets/appstore.png";
import classes from "./Weeklyplanner.module.css";

function Weeklyplanner() {
  return (
    <div className={classes.container}>
      <div className={classes.content_cards}>
        <img alt="icon" src={phone_icon} className={classes.img_icon}></img>
        <div>
          <h1 className={classes.title}>Tiny Planner: Tasks & To Do</h1>
          <h3 className={classes.title2}>
            Your go-to app for managing daily tasks with unparalleled simplicity
            and efficiency. Staying organized and on top of your schedule.
          </h3>
          <div className={classes.appstore}>
            <a href="https://apps.apple.com/cn/app/tiny-planner-tasks-to-do/id6472772555?l=en-GB">
              <img
                alt="appstore"
                src={appstore}
                className={classes.button_img}
              ></img>
            </a>
            <Link to={`/privacypolicy`} style={{ textDecoration: "none" }}>
              <h3>Privacy Policy</h3>
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.content_cards}>
        {/* 1,2 */}
        <h1 className={classes.sub_title}>
          Visualize your tasks and big days on different types of calendars
        </h1>
        <div className={classes.img_cards}>
          <img
            alt="1"
            style={{
              backgroundImage: `url("${phone_1_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_1}
            className={classes.img_box}
          ></img>
          <img
            alt="2"
            style={{
              backgroundImage: `url("${phone_2_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_2}
            className={classes.img_box}
          ></img>
        </div>
      </div>
      <div className={classes.content_cards}>
        {/* 3,6 */}
        <div className={classes.img_cards}>
          <img
            alt="3"
            style={{
              backgroundImage: `url("${phone_3_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_3}
            className={classes.img_box}
          ></img>
          <img
            alt="6"
            style={{
              backgroundImage: `url("${phone_6_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_6}
            className={classes.img_box}
          ></img>
        </div>
        <h1 className={classes.sub_title}>
          Simply plan your day and get reminders
        </h1>
      </div>
      <div className={classes.content_cards}>
        {/* 4,12 */}
        <h1 className={classes.sub_title}>Heatmap and Big days</h1>
        <div className={classes.img_cards}>
          <img
            alt="4"
            style={{
              backgroundImage: `url("${phone_4_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_4}
            className={classes.img_box}
          ></img>
          <img
            alt="12"
            style={{
              backgroundImage: `url("${phone_12_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_12}
            className={classes.img_box}
          ></img>
        </div>
      </div>
      <div className={classes.content_cards}>
        {/* 10,11 */}
        <div className={classes.img_cards}>
          <img
            alt="10"
            style={{
              backgroundImage: `url("${phone_10_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_10}
            className={classes.img_box}
          ></img>
          <img
            alt="11"
            style={{
              backgroundImage: `url("${phone_11_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_11}
            className={classes.img_box}
          ></img>
        </div>
        <h1 className={classes.sub_title}>Customize your calendars</h1>
      </div>
      <div className={classes.content_cards}>
        {/* 5,2 */}
        <h1 className={classes.sub_title}>Support multiple languages</h1>
        <div className={classes.img_cards}>
          <img
            alt="5"
            style={{
              backgroundImage: `url("${phone_5_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_5}
            className={classes.img_box}
          ></img>
          <img
            alt="2"
            style={{
              backgroundImage: `url("${phone_2_lower}")`,
              backgroundSize: "cover",
            }}
            src={phone_2}
            className={classes.img_box}
          ></img>
        </div>
      </div>
      <div className={classes.content_cards}>
        {/* 7,9 */}
        <div className={classes.img_cards}>
          <img
            alt="7"
            src={phone_7}
            style={{
              backgroundImage: `url("${phone_7_lower}")`,
              backgroundSize: "cover",
            }}
            className={classes.img_box}
          ></img>
          <img
            alt="9"
            src={phone_9}
            style={{
              backgroundImage: `url("${phone_9_lower}")`,
              backgroundSize: "cover",
            }}
            className={classes.img_box}
          ></img>
        </div>
        <h1 className={classes.sub_title}>Widgets</h1>
      </div>
    </div>
  );
}
export default Weeklyplanner;

// simple
// plan your day
// visualize your work on both type of calendar
// customize your theme
// get reminders

// no ads, no payments, accsisibility

// heatmap
// reminder
// simpliy create new task
// wedgit memo mutiple
// mutiple language
// big days
// theme
// two types of vwing
