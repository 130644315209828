import { React, useRef, useState } from "react";
import classes from "./Contact.module.css";

function Contact() {
  const [show, setShow] = useState(false);
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();

  const confirmHandler = (event) => {
    event.preventDefault();
    setShow(true);

    const enteredName = nameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredMessage = messageInputRef.current.value;

    fetch(
      "https://resume-d6970-default-rtdb.asia-southeast1.firebasedatabase.app/message.json",
      {
        method: "POST",
        body: JSON.stringify({
          name: enteredName,
          email: enteredEmail,
          message: enteredMessage,
        }),
      }
    );

    nameInputRef.current.value = "";
    emailInputRef.current.value = "";
    messageInputRef.current.value = "";
  };

  return (
    <div className={classes.contact}>
      {show && (
        <h1 className={classes.message}>Successfully sent the message!</h1>
      )}
      <h1 className={classes.title}>Contact Me</h1>
      <form action="">
        <input
          ref={nameInputRef}
          id="userName"
          type="text"
          placeholder="Your Full Name"
          required
        />
        <input
          ref={emailInputRef}
          id="email"
          type="email"
          placeholder="Your Email"
          required
        />
        <textarea
          ref={messageInputRef}
          id="message"
          rows="7"
          placeholder="Your Message"
          required
        ></textarea>
        <button
          onClick={confirmHandler}
          type="submit"
          className={classes.button}
        >
          Send Message
        </button>
      </form>
    </div>
  );
}

export default Contact;
