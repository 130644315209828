import classes from "./MobileProject.module.css";
import community from "../assets/community.png";
import increase1 from "../assets/increase1.png";
import increase2 from "../assets/increase2.png";
import login from "../assets/login.png";
import login2 from "../assets/login2.png";
import share1 from "../assets/share1.png";
import share2 from "../assets/share2.png";
function MobileProject() {
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <div className={classes.title_container}>
          <div className={classes.title}>
            <h1>Community collection application</h1>
            <h2>
              This application contributes to a more environmentally friendly
              society by helping people find places to collect hard rubbish such
              as furniture and improving their recycling habits.
            </h2>
            <a href="https://toiletr.herokuapp.com/">
              <button>View deployed version ➜</button>
            </a>
          </div>
        </div>
        <div className={classes.title_container}>
          <div className={classes.skill}>
            <h1>User Interface Design</h1>
            <h2>Front-end Development</h2>
          </div>
        </div>
      </div>

      <div className={classes.imgbox}>
        <img
          alt="title_img"
          src={community}
          className={classes.title_img}
        ></img>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_title}>
          <h1>Increase environmental awareness and recycling efforts</h1>
          <h2>
            Provide a new way to get furniture for families who can't find the
            right cheap furniture. Greatly increase the probability of hard
            rubbish being found around.
          </h2>
        </div>

        <div className={classes.content_container}>
          <img alt="increase1" src={increase1} className={classes.box}></img>
          <img
            alt="increase2"
            src={increase2}
            className={classes.overlay}
          ></img>
        </div>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_container1}>
          <img alt="login" src={login} className={classes.box1}></img>
          <img alt="login2" src={login2} className={classes.overlay1}></img>
        </div>

        <div className={classes.content_title}>
          <h1>Secure Account</h1>
          <h2>Secure login and logout, view profile and posts.</h2>
        </div>
      </div>

      <div className={classes.content_cards}>
        <div className={classes.content_title}>
          <h1>Share information and thoughts</h1>
          <h2>Comment others, post new location, find nearby hard rubbish.</h2>
        </div>

        <div className={classes.content_container}>
          <img alt="share2" src={share2} className={classes.box}></img>
          <img alt="share1" src={share1} className={classes.overlay}></img>
        </div>
      </div>
    </div>
  );
}

export default MobileProject;
