import classes from "./PrivacyPolicyPage.module.css";

function PrivacyPolicyPage() {
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Privacy Policy</h1>{" "}
      <p>
        Tiny Planner: Tasks & To Do app is a Free app, provided by Nanxi Li at
        no cost and is intended for use as is.
      </p>
      <p>
        This page is used to inform visitors regarding my policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use my Service.
      </p>
      <p>
        <strong>
          <u>We Collect No Personal Information Using Our Applications</u>
        </strong>
      </p>{" "}
      <p>
        We do not collect, use, save, or have access to any of your personal
        data recorded in Tiny Planner: Tasks & To Do for iOS. Any data that the
        app processes remains on the device.
      </p>{" "}
      <p>
        Individual settings relating to the Tiny Planner: Tasks & To Do are
        stored only on your device. You may be asked to provide access to your
        Notification center, this is the only way you can use Tiny Planner:
        Tasks & To Do reminder features. We do not process or collect that
        information and have no access to it.
      </p>{" "}
      <p>
        <strong>
          <u>
            There is No Third-party Service Providers or Links to Other Sites.
          </u>
        </strong>
      </p>{" "}
      <p>
        <strong>
          <u>Changes to This Privacy Policy</u>
        </strong>
      </p>{" "}
      <p>
        I may update our Privacy Policy from time to time. Thus, you are advised
        to review this page periodically for any changes. I will notify you of
        any changes by posting the new Privacy Policy on this page.
      </p>{" "}
      <p>This policy is effective as of 2023-11-22.</p>{" "}
      <p>
        <strong>
          <u>Contact Us</u>
        </strong>
      </p>{" "}
      <p>
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact me at nanxi8358@gmail.com.
      </p>
      <h1 className={classes.title}>隐私政策</h1>{" "}
      <p>
        Tiny Planner: Tasks & To Do 应用程序是一款免费应用程序，由Nanxi
        Li免费提供。
      </p>
      <p>
        此页面用于向访问者告知我的政策
        收集、使用和披露个人信息（如有）决定使用我的服务。
      </p>
      <p>
        <strong>
          <u>我们不会使用我们的应用程序收集任何个人信息</u>
        </strong>
      </p>{" "}
      <p>
        我们不会收集、使用、保存或访问您的任何个人信息在iOS版 Tiny Planner:
        Tasks & To
        Do中记录的数据。应用程序处理的任何数据将会保留在您自己的设备上。
      </p>{" "}
      <p>
        与 Tiny Planner: Tasks & To
        Do相关的个人设置仅存储在你的设备。您可能会被要求提供对您的通知的访问权限中心，这是您使用
        Tiny Planner: Tasks & To
        Do提醒功能的唯一方式。我们不会处理或收集该信息，也不会访问它。
      </p>{" "}
      <p>
        <strong>
          <u>没有第三方服务提供商或其他网站的链接。</u>
        </strong>
      </p>{" "}
      <p>
        <strong>
          <u>关于隐私政策的变更</u>
        </strong>
      </p>{" "}
      <p>
        我可能会不时更新我们的隐私政策。因此，建议您定期查看此页面是否有任何更改。
        我会通知您通过在此页面上发布新的隐私政策进行任何更改。
      </p>{" "}
      <p>这个政策自2023年11月22日起施行。</p>{" "}
      <p>
        <strong>
          <u>联系方式</u>
        </strong>
      </p>{" "}
      <p>
        如果您对我的隐私政策有任何疑问或建议，请与我联系 nanxi8358@gmail.com.
      </p>
    </div>
  );
}

export default PrivacyPolicyPage;
